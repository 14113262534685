import React, { FC, useEffect, useState } from 'react';
import { Seo } from '../components';
import { PageProps } from 'gatsby';
import { PageLayout } from '../components';
import config from '../../app-config.json';
import * as s from '../style.pages/index.style';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';


const PAGE_TITLE = config.pages[404].title;

//////////////////////////////////  component ////////////////////////////////
const PageNotFound: FC<PageProps> = ({ }) => {

    const [isMount, setMount] = useState(false);

    useEffect(() => {
        setMount(true);
    },[])


    if (!isMount) {
        return (
            <PageLayout>
                <Seo title={PAGE_TITLE} />
                <s.BodyWrap >
                    <br /><br /><br /><br /><br /><br /><br /><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center", height: "100%", width: '100%' }} >
                        <CircularProgress />
                    </div>
                </s.BodyWrap>
            </PageLayout>
        )
    }


    return (
        <PageLayout>
            <Seo title={PAGE_TITLE} />
            <s.BodyWrap >
                <Typography variant='h2' >Sorry, Page Not Found</Typography>
            </s.BodyWrap>
        </PageLayout>
    )


}

export default PageNotFound;